import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Main from "./page/Main/Main";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Portfolio from "./page/Portfolio/Portfolio";
import About from "./page/About/About";
import Contact from "./page/Contact/Contact";
import Services from "./page/Services/Services";
import Implementation from "./page/Implementation/Implementation";
import CallButton from "./components/CallButton/CallButton";
import Popup from "./components/Popup/Popup"
// import NotFound from '../pages/NotFound'

import { useState } from 'react';

const App = () => {
    const [showPopup, setShowPopup] = useState(false);

    const handleClosePopup = () => {
        setShowPopup(false);
    }


    return (
        <Router>
            <Header />
            <Routes>
                <Route exact path="/" element={<Main />}/>
                <Route exact path="/portfolio" element={<Portfolio />}/>
                <Route exact path="/about" element={<About />}/>
                <Route exact path="/contact" element={<Contact />}/>
                <Route exact path="/services" element={<Services />}/>
                <Route exact path="/implementation" element={<Implementation />}/>
                <Route path="*" element={<Main />}/>
            </Routes>
            <Footer />
            <CallButton showMail={showPopup} setShowMail={setShowPopup}/>
            { showPopup && <Popup onClosePopup={handleClosePopup} /> }
        </Router>
    );
}

export default App;