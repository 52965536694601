import W1 from './photo-1.jpg';
import W2 from './photo-2.jpg';
import W3 from './photo-3.jpg';
import W4 from './photo-4.jpg';
import W5 from './photo-5.jpg';
import W6 from './photo-6.jpg';
import W7 from './photo-7.jpg';
import W8 from './photo-8.jpg';
import W9 from './photo-9.jpg';
import W10 from './photo-10.jpg';
import W11 from './photo-11.jpg';
import W12 from './photo-12.jpg';
import W13 from './photo-13.jpg';
import W14 from './photo-14.jpg';
import W15 from './photo-15.jpg';
import W16 from './photo-16.jpg';
import W17 from './photo-17.jpg';
import W18 from './photo-18.jpg';
import W19 from './photo-19.jpg';
import W20 from './photo-20.jpg';
import W21 from './photo-21.jpg';
import W23 from './photo-22.jpg';
import W24 from './photo-23.jpg';
import W25 from './photo-24.jpg';
import W26 from './photo-26.jpg';
import W27 from './photo-27.jpg';
import W28 from './photo-28.jpg';
import W29 from './photo-29.jpg';
import W30 from './photo-30.jpg';
import W31 from './photo-31.jpg';
import W32 from './photo-32.jpg';
import W33 from './photo-32.jpg';
import W34 from './photo-32.jpg';
import W35 from './photo-32.jpg';
import W36 from './photo-32.jpg';
import W37 from './photo-37.jpg';
import W38 from './photo-38.jpg';
import W39 from './photo-39.jpg';
import W40 from './photo-40.jpg';
import W41 from './photo-41.jpg';
import W42 from './photo-42.jpg';
import W43 from './photo-43.jpg';
import W44 from './photo-44.jpg';
import W45 from './photo-45.jpg';
import W46 from './photo-46.jpg';
import W47 from './photo-47.jpg';
import W48 from './photo-48.jpg';

const family = [W1, W2, W3, W4, W5, W6, W7, W8, W9, W10, W11, W12, W13, W14, W15, W16, W17, W18, W19, W20, W21, W23, W24, W25, W26, W27, W28, W29, W30, W31, W32, W33, W34, W35, W36, W37, W38, W39, W40, W41, W42, W43, W44, W45, W46, W47, W48];

export default family;