import W1 from './photo-1.JPG';
import W2 from './photo-2.JPG';
import W3 from './photo-3.JPG';
import W4 from './photo-4.JPG';
import W5 from './photo-5.JPG';
import W6 from './photo-6.JPG';
import W7 from './photo-7.JPG';
import W8 from './photo-8.JPG';
import W9 from './photo-9.JPG';
import W10 from './photo-10.JPG';
import W11 from './photo-11.JPG';
import W12 from './photo-12.JPG';
import W13 from './photo-13.JPG';
import W14 from './photo-14.JPG';
import W15 from './photo-15.JPG';
import W16 from './photo-16.JPG';
import W17 from './photo-17.JPG';
import W18 from './photo-18.JPG';
import W19 from './photo-19.JPG';
import W20 from './photo-20.JPG';
import W21 from './photo-21.JPG';
import W23 from './photo-22.JPG';
import W24 from './photo-23.JPG';
import W25 from './photo-24.JPG';
import W26 from './photo-26.JPG';

const academy = [W1, W2, W3, W4, W5, W6, W7, W8, W9, W10, W11, W12, W13, W14, W15, W16, W17, W18, W19, W20, W21, W23, W24, W25, W26];

export default academy;