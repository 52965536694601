import React, { useState } from 'react';
import './CallButton.css';

import telegram from "../../images/telegram.png";
import mail from "../../images/mail.png";
import whatsapp from "../../images/whatsapp.png";

const CallButton = ({ showMail, setShowMail }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [telegramLink, setTelegramLink] = useState("https://t.me/yanabreyksh");
    const [whatsAppLink, setWhatsAppLink] = useState("https://wa.me/79652893572?text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82...");


    const togglePopup = () => {
        setShowPopup(!showPopup);
    }

    const toggleMail = () => {
        setShowMail(!showMail);
        setShowPopup(!showPopup);
    }

    const openTelegramLink = () => {
        window.open(telegramLink, '_blank');
    }

    const openWhatsAppLink = () => {
        window.open(whatsAppLink, '_blank');
    }

    const popupClass = showPopup ? "popup-buttons show" : "popup-buttons";

    return (
        <div className="call-button-container">
            <button className="call-button" onClick={togglePopup}>
                Связаться с нами
            </button>
            {showPopup && (
                <div className={popupClass}>
                    <button className="popup-button" onClick={openTelegramLink}><img className='popup-icon' src={telegram} alt="telegram logo" /></button>
                    <button className="popup-button" onClick={openWhatsAppLink}><img className='popup-icon' src={whatsapp} alt="telegram logo" /></button>
                    <button className="popup-button" onClick={toggleMail}><img className='popup-icon' src={mail} alt="telegram logo" /></button>
                </div>
            )}
        </div>
    );
}

export default CallButton;
