import W1 from './photo-1.png';
import W2 from './photo-2.png';
import W3 from './photo-3.png';
import W4 from './photo-4.png';
import W5 from './photo-5.png';
import W6 from './photo-6.png';
import W7 from './photo-7.png';
import W8 from './photo-8.png';
import W9 from './photo-9.png';
import W10 from './photo-10.png';
import W11 from './photo-11.png';
import W12 from './photo-12.png';
import W13 from './photo-13.png';
import W14 from './photo-14.png';
import W15 from './photo-15.png';
import W16 from './photo-16.png';
import W17 from './photo-17.png';
import W18 from './photo-18.png';
import W19 from './photo-19.png';
import W20 from './photo-20.png';

const cityKorolev = [W1, W2, W3, W4, W5, W6, W7, W8, W9, W10, W11, W12, W13, W14, W15, W16, W17, W18, W19, W20];

export default cityKorolev;