import './About.css';
import about from "../../images/about.jpg";

function About() {
    return (
        <div className="about">
            <div className='about-content'>
                <div className='about-block-image'>
                    <img className='about-image' src={about} alt="О нас вмасштабе" />
                </div>
                <div className='about-block'>
                    <p className='about-text'>
                        Мы Анастасия и Яна- основатели и руководители дизайн студии Вмасштабе.

                        Наша студия это:

                        Более 50 проектов от маленьких студий до 1000 кв м офисных помещений.

                        Детальный подход к разработке и реализации дизайн-проекта (от первого замера до последней шторки в вашем доме).

                        Ориентир строго на бюджет клиента, чтобы ремонт стал увлекательным и интересным периодом в вашей жизни.

                        Команда профессионалов, которая возьмет все хлопоты на себя.

                        Вариативность по наполнению пакета по запросу клиента (сможете выбрать дизайн-проект на свой бюджет).

                        Помощь клиентам даже после сдачи дизайн-проекта.

                        Мы всегда на связи и рады ответить на любые вопросы.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;