import React, { useState } from 'react';
import './Popup.css';
import emailjs from 'emailjs-com';
import Loader from "../Loader/Loader";

const Popup = (props) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [popupText, setPopupText] = useState("Оставьте заявку");

    const handleClose = () => {
        props.onClosePopup();
    }

    const handleClick = () => {
        const serviceID = 'service_265a4am';
        const templateID = 'template_jy8025h';
        const userID = 'yCS6AtCC3R8oklCiy';

        const templateParams = {
            name: name,
            phone: phone,
            comment: comment
        };

        setIsLoading(true);

        emailjs.send(serviceID, templateID, templateParams, userID)
            .then(() => {
                setIsSubmitted(true);
                setIsLoading(false);
                setPopupText("Заявка отправлена");
                setTimeout(props.onClosePopup, 2000);
            })
            .catch(() => {
                setIsSubmitted(true);
                setIsLoading(false);
                setPopupText("Ошибка, повторите попытку позже");
                setTimeout(props.onClosePopup, 2000);
            });
    }

    return (
        <div className="popup">
            <button className="button button_type_close" type="button" onClick={handleClose}></button>
            <div className="popup__container">
                {!isLoading && <h2 className="popup__title">{popupText}</h2>}
                {isLoading ? (
                    <Loader />
                ) : (
                    !isSubmitted && (
                        <form className="popup__form" name="form" onSubmit={(e) => e.preventDefault()}>
                            <input
                                className="popup__input popup__input_type_name"
                                type="text"
                                name="name"
                                value={name}
                                placeholder="Имя"
                                onChange={(e) => setName(e.target.value)}
                            />
                            <input
                                className="popup__input popup__input_type_status"
                                name="phone"
                                value={phone}
                                placeholder="Телефон или почта"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            <input
                                className="popup__input popup__input_type_status"
                                name="comment"
                                value={comment}
                                placeholder="Комментарий"
                                onChange={(e) => setComment(e.target.value)}
                            />
                            <button className="button button_type_save" type="submit" onClick={handleClick}>
                                Отправить
                            </button>
                        </form>
                    )
                )}
            </div>
        </div>
        );
}

export default Popup;
