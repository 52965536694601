import W1 from './photo-1.jpg';
import W2 from './photo-2.jpg';
import W3 from './photo-3.jpg';
import W4 from './photo-4.jpg';
import W5 from './photo-5.jpg';
import W6 from './photo-6.jpg';
import W7 from './photo-7.jpg';
import W8 from './photo-8.jpg';
import W9 from './photo-9.jpg';
import W10 from './photo-10.jpg';
import W11 from './photo-11.jpg';
import W12 from './photo-12.jpg';
import W13 from './photo-13.jpg';
import W14 from './photo-14.jpg';
import W15 from './photo-15.jpg';
import W16 from './photo-16.jpg';
import W17 from './photo-17.jpg';
import W18 from './photo-18.jpg';
import W19 from './photo-19.jpg';
import W20 from './photo-20.jpg';
import W21 from './photo-21.jpg';
import W23 from './photo-22.jpg';
import W24 from './photo-23.jpg';
import W25 from './photo-24.jpg';
import W26 from './photo-26.jpg';
import W27 from './photo-27.jpg';
import W55 from './photo-55.jpg';
import W56 from './photo-56.jpg';
import W57 from './photo-57.jpg';
import W58 from './photo-58.jpg';
import W59 from './photo-59.jpg';
import W60 from './photo-60.jpg';
import W61 from './photo-61.jpg';
import W62 from './photo-62.jpg';
import W63 from './photo-63.jpg';
import W64 from './photo-64.jpg';
import W65 from './photo-65.jpg';
import W66 from './photo-66.jpg';
import W67 from './photo-67.jpg';
import W68 from './photo-68.jpg';
import W69 from './photo-69.jpg';
import W70 from './photo-70.jpg';
import W71 from './photo-71.jpg';

const chehov = [W1, W2, W3, W4, W5, W6, W7, W8, W9, W10, W11, W12, W13, W14, W15, W16, W17, W18, W19, W20, W21, W23, W24, W25, W26, W27,
W55, W56, W57, W58, W59, W60, W61, W62, W63, W64, W65, W66, W67, W68, W69, W70, W71];

export default chehov;