import './Contact.css';

function Contact() {
    const line = (
        <div style={{ flex: 1, backgroundColor: "#000", height: "1px", margin: '30px 0' }} />
    );

    return (
        <div className="contact">
            <div className='contact-content'>
                <div className='contact-header'>
                    { line }
                    <h1 className='contact-title'>Контакты</h1>
                    { line }
                </div>
                <p className='contact-text-phone'>ТЕЛ.:</p>
                <p className='contact-text-phone'>
                    <a href='tel:79267583648' className='contact-text-phone'>Яна - +7 (926) 758-36-48</a>
                </p>
                <p className='contact-text-phone'>
                    <a href='tel:79652893572' className='contact-text-phone'>Анастасия - +7 (965) 289-35-72</a>
                </p>
                <p className='contact-text'>E-MAIL:  <a href='mailto:Vmasshtabedesign@ya.ru' className='contact-text'>Vmasshtabedesign@ya.ru</a></p>
                <p className='contact-text'>Мы постоянно находимся в поиске единомышленников в свою команду! Если вы архитектор, дизайнер-визуализатор, комплектатор или менеджер проектов и вам близка наша эстетика - ждем ваше портфолио на почту: <a href='mailto:Vmasshtabedesign@ya.ru' className='contact-text'>Vmasshtabedesign@ya.ru</a></p>
                <div style={{ width: '100%', backgroundColor: "#000", height: "1px", margin: '30px 0' }} />
            </div>
        </div>
    );
}

export default Contact;