import './Menu.css';
import { Link } from 'react-router-dom'

function Menu() {
    const point = (
        <p className="menu__point">
            &#8226;
        </p>
    );
    return (
            <nav className="menu">
                <ul className='menu__links'>
                    <li className="menu__list-item">
                    <Link className="menu__list-item" to="/about">

                            О Нас
                    </Link>
                    </li>
                    {point}
                    <li className="menu__list-item">
                        <Link className="menu__list-item" to="/services">
                            Услуги
                        </Link>
                    </li>
                    {point}
                    <li className="menu__list-item">
                        <Link className="menu__list-item" to="/implementation">
                            Реализация
                        </Link>
                    </li>
                    {point}
                    <li className="menu__list-item">
                        <Link className="menu__list-item" to="/portfolio">
                            Портфолио
                        </Link>
                    </li>
                    {point}
                    <li className="menu__list-item">
                        <Link className="menu__list-item" to="/contact">
                            Контакты
                        </Link>
                    </li>
                </ul>
            </nav>
    );
}

export default Menu;