import './Services.css';

import basePDF from '../../pdf/base.pdf'
import ecoPDF from '../../pdf/econom.pdf'
import individPDF from '../../pdf/individual.pdf'
import businessPDF from '../../pdf/business.pdf'

import iconPDF from "../../images/pdf.png";

function Services() {
    const check = (plus) => plus && (<p className='services-plus'>&#43;</p>);
    const pdf = (pdf) => (
        <a href={pdf} target="_blank" rel="noreferrer">
            <img className='services-pdf' src={iconPDF} alt="PDF" />
        </a>
    );

    return (
        <div className="services">
            <div className='services-content'>
                <h3 className='service-header'>Наш прайс</h3>
                <div className='services-row-header'>
                    <div className='services-row'>
                        <p className='services-title-header'>Пакет</p>
                        <div className='services-coin'><p className='services-text-header'>Базовый</p></div>
                        <div className='services-coin'><p className='services-text-header'>Стандарт</p></div>
                        <div className='services-coin'><p className='services-text-header'>Комфорт</p></div>
                        <div className='services-coin'><p className='services-text-header'>Бизнес</p></div>
                    </div>
                    <div style={{ backgroundColor: "#000", height: "1px", margin: '0' }} />
                    <div className='services-row'>
                        <p className='services-subtitle-header'>Стоимость работ</p>
                        <div className='services-coin'><p className='services-subtext-header'>500 РУБ/М2</p></div>
                        <div className='services-coin'><p className='services-subtext-header'>1000 РУБ/М2</p></div>
                        <div className='services-coin'><p className='services-subtext-header'>2500 РУБ/М2</p></div>
                        <div className='services-coin'><p className='services-subtext-header'>3500 РУБ/М2</p></div>
                    </div>
                </div>
                <div className='services-row'>
                    <p className='services-title'>Обмерный план с привязкой инженерных коммуникаций</p>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'>
                    <p className='services-title'>План демонтируемых конструкций</p>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'>
                    <p className='services-title'>План возводимых конструкций</p>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'>
                    <p className='services-title'>Несколько вариантов планировочного решения (до 10 правок)</p>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'></div>
                <div className='services-row'>
                    <p className='services-title'>План потолков с указанием типа используемого материала, отдельных узлов и сечений;
                        <br />План привязки розеток и выключателей; <br />План размещения осветительных приборов, привязка, выпусков освещения; <br />Схема групп подключения осветительных приборов;
                        <br />План размещения электрического подогрева пола с привязкой регулятора; <br />План привязки сантехнического оборудования;</p>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'></div>
                <div className='services-row'>
                    <p className='services-title'>План напольных покрытий с указанием типа и площадей</p>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'>
                    <p className='services-title'>План отделки стен</p>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'>
                    <p className='services-title'>Развертки по всем стенам</p>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'>
                    <p className='services-title'>Ведомость отделочных материалов в таблице Excel</p>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'>
                    <p className='services-title'>Ведомость осветительных приборов в таблице Excel</p>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'>
                    <p className='services-title'>Ведомость электрооборудования в таблице Excel</p>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'>
                    <p className='services-title'>Ведомость сантехники в таблице Excel</p>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'>
                    <p className='services-title'>Ведомость мебели в таблице Excel</p>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'>
                    <p className='services-title'>3D - визуализация интерьеров помещений</p>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(false) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                    <div className='services-coin'>{ check(true) }</div>
                </div>
                <div style={{ backgroundColor: "#b0b0b0", height: "1px", margin: '2px' }} />
                <div className='services-row'>
                    <p className='services-title'>Пример альбома</p>
                    <div className='services-coin'>{ pdf(basePDF) }</div>
                    <div className='services-coin'>{ pdf(ecoPDF) }</div>
                    <div className='services-coin'>{ pdf(individPDF) }</div>
                    <div className='services-coin'>{ pdf(businessPDF) }</div>
                </div>

                <p className='services-footer'>Выезд на объект вне дизайн-проекта в рабочее время по Москве - 5000 руб.</p>
            </div>
        </div>
    );
}

export default Services;