import './Implementation.css';

import React, { useState } from 'react';
import ReactBnbGallery from "react-bnb-gallery";

import HeartOfTheCapital from "../../images/implementation/HeartOfTheCapital/photo-18.png";
import CapitalGlades from "../../images/implementation/CapitalGlades/photo-21.jpg";
import CityKorolev from "../../images/implementation/cityKorolev/photo-3.png";
import Falicity from "../../images/implementation/Filicity/photo-9.jpg";
import SeligerCity from "../../images/implementation/seligerCity/photo-4.jpg"
import August from "../../images/implementation/august/photo-4.jpg"


import HOTC from "../../images/implementation/HeartOfTheCapital/index";
import CG from "../../images/implementation/CapitalGlades/index";
import cityKorolev from "../../images/implementation/cityKorolev/index";
import filicity from "../../images/implementation/Filicity/index";
import seligerCity from "../../images/implementation/seligerCity/index";
import august from "../../images/implementation/august/index";

const portfolio = [
    {
        firstImage:  August,
        name: "ЖК Символ",
        all: august
    },
    {
        firstImage:  HeartOfTheCapital,
        name: "Жк Сердце Столицы",
        all: HOTC
    },
    {
        firstImage:  CapitalGlades,
        name: "Жк Столичные поляны",
        all: CG
    },
    {
        firstImage:  CityKorolev,
        name: "Город Королев",
        all: cityKorolev
    },
    {
        firstImage:  Falicity,
        name: "ЖК Filicity",
        all: filicity
    },
    {
        firstImage:  SeligerCity,
        name: "ЖК Cелигер Cити",
        all: seligerCity
    }
]

function Implementation() {
    const [isOpen, setIsOpen] = useState(false);
    const [isPhoto, setIsPhoto] = useState(HOTC);

    function handleWatchedChange(item) {
        setIsPhoto(item.all)
        setIsOpen(true);
    }

    const items = portfolio.map((item) =>
        <div className='implementation_block' onClick = {() => handleWatchedChange(item)} >
            <img className='implementation_image' src={item.firstImage} alt={item.name} />
            <p className='implementation_title'>{ item.name }</p>
        </div>
    );

    return (
        <div className="implementation">
            { items }
            <ReactBnbGallery
                show={isOpen}
                photos={isPhoto}
                opacity={0.9}
                showThumbnails={true}
                phrases={"Показать"}
                onClose={() => setIsOpen(false)}
            />
        </div>
    );
}

export default Implementation;