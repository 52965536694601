import { Link } from 'react-router-dom'
import logo from "../../images/logo.png";

import './Header.css';
import Menu from "../Menu/Menu";

function Header() {
    return (
            <header className='header'>
                <Link to="/"><img className='header_logo' src={logo} alt="vmashtabe logo" /></Link>
                <Menu />
            </header>
    );
}

export default Header;