import instagram from "../../images/instagram.png";
import telegram from "../../images/telegram.png";
import mail from "../../images/mail.png";

import './Footer.css';

function Footer() {
    return (
            <footer className='footer'>
                <a href='mailto:Vmasshtabedesign@ya.ru' ><img className='footer_logo' src={mail} alt="instagram logo" /></a>
                <a href='https://www.instagram.com/v.masshtabe/'><img className='footer_logo' src={instagram} alt="instagram logo" /></a>
                <a href='https://t.me/vmasshtabe'><img className='footer_logo' src={telegram} alt="telegram logo" /></a>
            </footer>
    );
}

export default Footer;