import './Main.css';
import Carousel from "../../components/Corusell/Corusell";
import Maininfo from "../../components/MainInfo/Maininfo";

function Main() {
    return (
        <div className="main">
            <Carousel />
            <Maininfo />
        </div>
    );
}

export default Main;