import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image1 from "../../images/main-page/1.jpg";
import image2 from "../../images/main-page/2.jpg";
import image3 from "../../images/main-page/3.jpg";
import image4 from "../../images/main-page/4.jpg";
import image5 from "../../images/main-page/5.jpg";

import './Corusell.css'

function SampleNextArrow(props) {
    // const { className, style, onClick } = props;
    return (
        <div />
    );
}

function SamplePrevArrow(props) {
    // const { className, style, onClick } = props;
    return (
        <div />
    );
}

export default function Corusell() {
    const settings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        pauseOnHover: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    return (
            <Slider {...settings}>
                <div className='slider_item'>
                    <img className='slider_image' src={image1} alt='вмасштабе проект' />
                </div>
                <div className='slider_item'>
                    <img className='slider_image' src={image2} alt='вмасштабе проект' />
                </div>
                <div className='slider_item'>
                    <img className='slider_image' src={image3} alt='вмасштабе проект' />
                </div>
                <div className='slider_item'>
                    <img className='slider_image' src={image4} alt='вмасштабе проект' />
                </div>
                <div className='slider_item'>
                    <img className='slider_image' src={image5} alt='вмасштабе проект' />
                </div>
            </Slider>
    );
}