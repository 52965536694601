import './Maininfo.css';

import text from "../../images/Group 1.png";

function Maininfo() {
    return (
        <div className='main-info'>
            <div className='main-info_imagepkus'>
                <h3 className='main-info_subtitle'>Впишемся в ваш бюджет</h3>
                <img className='main-info_image' src={text} alt='with love' />
            </div>
            <p className='main-info_text'>авторское сопровождение / дизайн интрьеров / комплектация под ключ</p>
            <a href='mailto:Vmasshtabedesign@ya.ru' className='main-info_email'>Vmasshtabedesign@ya.ru</a>
        </div>
    );
}

export default Maininfo;