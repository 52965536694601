import W1 from './photo-1.jpg';
import W3 from './photo-3.jpg';
import W4 from './photo-4.jpg';
import W5 from './photo-5.jpg';
import W6 from './photo-6.jpg';
import W7 from './photo-7.jpg';
import W8 from './photo-8.jpg';
import W9 from './photo-9.jpg';
import W10 from './photo-10.jpg';
import W11 from './photo-11.jpg';

const august = [W1, W3, W4, W5, W6, W7, W8, W9, W10, W11];

export default august;